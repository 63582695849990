<template>
  <b-overlay
    :show="showOverlay"
    rounded="sm"
  >
    <div class="misc-wrapper">
      <!-- Brand logo-->
      <b-link
        class="brand-logo"
        style="top: 0rem !important;"
      >
        <div>
          <td style="vertical-align: middle;">
            <logo
              style="height: 50px; width: auto; margin-top: 1rem;"
            />
          </td>
          <td style="vertical-align: middle;">
            <h2
              class="brand-text text-primary"
              style="margin-top: 20px; margin-left: 0.5rem; text-align: center;"
            >
              {{ appName }}
            </h2>
          </td>
        </div>
      </b-link>
      <!-- /Brand logo-->

      <div class="misc-inner p-2 p-sm-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">
            {{ $t('Connect to YRU Passport') }}
          </h2>
          <p class="mb-2">
            {{ $t('Please wait, the system is processing' ) }}
          </p>
          <b-alert
            v-if="alertMessage !== ''"
            show
            fade
            class="mb-0 mx-1"
            variant="danger"
          >
            <div class="alert-body">
              <span>{{ alertMessage }}</span>
            </div>
          </b-alert>
          <div>
            <b-button
              variant="primary"
              class="mt-2"
              @click="onBack"
            >
              <feather-icon icon="ChevronLeftIcon" />
              {{ $t('Back') }}
            </b-button>
          </div>
          <b-img
            fluid
            :src="imgUrl"
          />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BImg,
  BOverlay,
  BAlert,
  BButton,
} from 'bootstrap-vue'
import Logo from '@/@core/layouts/components/Logo.vue'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'
import {
  Toast,
} from 'vant'
import {
  onUnmounted,
} from '@vue/composition-api'
import storeYrupassport from './storeYrupassport'

export default {
  components: {
    BLink,
    BImg,
    BOverlay,
    BAlert,
    BButton,
    Logo,
  },
  data() {
    return {
      showOverlay: false,
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      skin: store.state.appConfig.layout.skin,
      alertMessage: '',
      appCode: $themeConfig.app.appCode,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
    routeParams() {
      return this.$route.params
    },
    routeQuery() {
      return this.$route.query
    },
    codeQuery() {
      if (this.routeQuery.code !== undefined) {
        return this.routeQuery.code
      }
      return ''
    },
    stateQuery() {
      if (this.routeQuery.state !== undefined) {
        return this.routeQuery.state
      }
      return ''
    },
    errorQuery() {
      if (this.routeQuery.error !== undefined) {
        return this.routeQuery.error
      }
      return ''
    },
    errorDescQuery() {
      if (this.routeQuery.error_description !== undefined) {
        return this.routeQuery.error_description
      }
      return ''
    },
  },
  mounted() {
    this.alertMessage = ''
    if (this.codeQuery !== '') {
      this.requestToken()
    }
  },
  methods: {
    onBack() {
      this.$router.push({ name: 'auth-login' })
    },
    requestToken() {
      this.showOverlay = true
      store.dispatch('yruPassport/requestToken', {
        code: this.codeQuery,
      })
        .then(response => {
          this.showOverlay = false
          store.commit('yruPassport/UPDATE_TOKEN', response.data)
          this.getProfile(response.data.access_token)
        })
        .catch(error => {
          this.showOverlay = false
          const { response, message } = error
          if (response) {
            let errorMsg = this.$i18n.t(response?.data.error)
            if (errorMsg === '') {
              errorMsg = this.$i18n.t(response?.data.message)
            }
            let alertMsg = this.$i18n.t(response?.data.message)
            if (alertMsg === '') {
              alertMsg = errorMsg
            }
            this.alertMessage = alertMsg
            Toast({
              message: errorMsg,
              icon: 'warning-o',
              position: 'middle',
              duration: 4000,
            })
          } else if (message) {
            this.alertMessage = message
            Toast({
              message,
              icon: 'warning-o',
              position: 'middle',
              duration: 4000,
            })
          }
        })
    },
    getProfile(token) {
      if (token !== '' && token !== undefined) {
        this.showOverlay = true
        store.dispatch('yruPassport/getProfile', token)
          .then(response => {
            store.commit('yruPassport/UPDATE_PROFILE', response.data)
            this.showOverlay = false
            this.$router.push({ name: 'yrupassport-profile' })
          })
          .catch(error => {
            this.showOverlay = false
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              let alertMsg = this.$i18n.t(response?.data.message)
              if (alertMsg === '') {
                alertMsg = errorMsg
              }
              this.alertMessage = alertMsg
              Toast({
                message: errorMsg,
                icon: 'warning-o',
                position: 'middle',
                duration: 4000,
              })
            } else if (message) {
              this.alertMessage = message
              Toast({
                message,
                icon: 'warning-o',
                position: 'middle',
                duration: 4000,
              })
            }
          })
      } else {
        this.alertMessage = this.$i18n.t('Process on error')
        Toast({
          message: this.$i18n.t('Process on error'),
          icon: 'warning-o',
          position: 'middle',
          duration: 4000,
        })
      }
    },
  },
  setup() {
    const { appName } = $themeConfig.app

    if (!store.hasModule('store-yrupassport')) store.registerModule('store-yrupassport', storeYrupassport)
    onUnmounted(() => {
      if (store.hasModule('store-yrupassport')) store.unregisterModule('store-yrupassport')
    })

    return {
      appName,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
